<template>
  <div class="panel">
    <LogoutBtn />
    <BalanceStatus class="panel__right" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LogoutBtn from './buttons/logoutBtn.vue';
import BalanceStatus from './BalanceStatus.vue';
export default {
  computed: {
    ...mapState(["Role"]),
  },
  components: { LogoutBtn, BalanceStatus },
}
</script>

<style lang="sass">
.panel
  display: flex
  gap: 10px
  &__right
    margin-left: auto
  &__gap
    margin-left: 20px
  select
    margin-right: 10px
  button
    font-weight: 700
    width: 40px
    height: 40px
    max-height: 40px
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    line-height: 0
</style>