import { createStore } from 'vuex'

export default createStore({
  state: {
    url: '',
    loads: {},
    Role: null,
    tasks: [],
    toDownload: [],
    apimodes: [
      {
        title: 'Проверка сезонности запросов',
        component: 'Wordstat3'
      }
    ]
  },
  mutations: {
    setToDownload(state, toDownload) {
      state.toDownload = toDownload
    },
    setUrl(state, url) {
      state.url = url
    },
    setTasks(state, tasks) {
      state.tasks = tasks
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    }
  },
  actions: {
    reset({commit}){
      commit('setRole', false)
    },
    async checkAuth({state,commit}){
      commit('toggleLoad','checkAuth')
      await fetch(state.url + 'auth', {
        credentials: 'include',
      })
        .then(res=>{
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role=>{
          commit('setRole', role || false)
        }).catch(err=>{
          alert(err)
          commit('setRole', false)
        })
      commit('toggleLoad','checkAuth')
    },
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
  }
})
