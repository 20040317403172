<template>
  <div class="downer wordstat3">
    <label class="label">
      <span class="label__text">Регион</span>
      <select class="select" v-model="region">
        <option v-for="(value, key) in sortedregions" :value="value" :key="key">{{ key }}</option>
      </select>
    </label>
    <label class="label">
      <span class="label__text">Тип устройства</span>
      <select class="select" v-model="device">
        <option value="">Все</option>
        <option value="desktop">Десктоп</option>
        <option value="mobile">Мобильные</option>
        <option value="phone">Только телефоны</option>
        <option value="tablet">Только планшеты</option>
      </select>
    </label>
    <textarea v-model="words"></textarea>
    <Btn @click="makeRequest">Сделать запрос</Btn>
  </div>
</template>

<script>
import Btn from '@/components/buttons/Btn.vue'
import { mapState } from 'vuex/dist/vuex.cjs.js';

export default {
  components: { Btn },
  data: () => ({
    region: '225',
    device: '',
    words: '',
    allregions: {
      'Москва и Московская область': '1',
      'Санкт-Петербург': '2',
      'Белгород': '4',
      'Иваново': '5',
      'Кострома': '7',
      'Курск': '8',
      'Липецк': '9',
      'Орел': '10',
      'Рязань': '11',
      'Смоленск': '12',
      'Тамбов': '13',
      'Тверь': '14',
      'Тула': '15',
      'Ярославль': '16',
      'Архангельск': '20',
      'Вологда': '21',
      'Калининград': '22',
      'Мурманск': '23',
      'Великий Новгород': '24',
      'Псков': '25',
      'Махачкала': '28',
      'Нальчик': '30',
      'Владикавказ': '33',
      'Краснодар': '35',
      'Ставрополь': '36',
      'Астрахань': '37',
      'Волгоград': '38',
      'Ростов-на-Дону': '39',
      'Йошкар-Ола': '41',
      'Саранск': '42',
      'Казань': '43',
      'Чебоксары': '45',
      'Нижний Новгород': '47',
      'Оренбург': '48',
      'Пенза': '49',
      'Пермь': '50',
      'Самара': '51',
      'Курган': '53',
      'Екатеринбург': '54',
      'Челябинск': '56',
      'Красноярск': '62',
      'Иркутск': '63',
      'Кемерово': '64',
      'Новосибирск': '65',
      'Омск': '66',
      'Томск': '67',
      'Владивосток': '75',
      'Хабаровск': '76',
      'Благовещенск': '77',
      'Беларусь': '149',
      'Казахстан': '159',
      'Уфа': '172',
      'Украина': '187',
      'Брянск': '191',
      'Владимир': '192',
      'Воронеж': '193',
      'Ульяновск': '195',
      'Барнаул': '197',
      'Москва': '213',
      'Россия': '225',
      'Сочи': '239',
      'Сургут': '973',
      'Назрань': '1092',
      'Черкесск': '1104',
      'Грозный': '1106',
    }
  }),
  methods: {
    async makeRequest() {
      console.log(this.wordsArray)
      this.$store.commit('toggleLoad', 'makeRequest')

      await fetch(this.url + `wordstat`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ words: this.wordsArray, region: this.region, device: this.device })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          const id = await res.text()
          this.$store.commit('setToDownload', [...this.toDownload, id])
          while (!this.tasks.find(el => [el.id, '' + el.id].includes(id)))
            await new Promise(resolve => setTimeout(resolve, 500))
        })
        .catch(err => { console.error(err); alert(err) })
      this.$store.commit('toggleLoad', 'makeRequest')
    }
  },
  computed: {
    ...mapState(['url','toDownload','tasks']),
    wordsArray() {
      return this.words.split('\n').map(el => el.trim()).filter(el => el)
    },
    sortedregions(){
      const obj = {}
      const keys = Object.keys(this.allregions).sort((a,b) => a.localeCompare(b))
      keys.forEach(key => obj[key] = this.allregions[key])
      return obj
    }
  }
}
</script>

<style lang="sass">
</style>