<template>
  <div class="apimodewrapper">
    <h1 class="apimodewrapper__title">{{ title }}</h1>
    <component :is="component" />
  </div>
</template>

<script>
import Wordstat3 from './apimodes/Wordstat3.vue'

export default {
  components: { Wordstat3 },
  props: {
    title: { type: String, required: true },
    component: { type: String, required: true },
  }
}
</script>

<style lang="sass">
.apimodewrapper
  &__title
    font-size: 20px
    font-weight: 700
</style>