<template>
  <div class="balanceStatus">
    <div class="balanceStatus__text">Число лимитов: <strong>{{ balance }}</strong></div>
  </div>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.cjs.js';

export default {
  data: () => ({
    balance: '',
    interval: null
  }),
  methods: {
    updateBalance() {
      fetch(this.url + 'balance', { credentials: 'include' })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.json()
        }).then(json => {
          this.balance = json?.limits_total ?? 'Ошибка'
        }).catch(err => { console.error(err); alert(err) })
    }
  },
  mounted() {
    if (!this.interval) {
      this.updateBalance()
      this.interval = setInterval(this.updateBalance, 60000)
    }
  },
  unmounted() {
    clearInterval(this.interval)
    this.interval = null
  },
  computed: {
    ...mapState(['url'])
  }
}
</script>

<style lang="sass">
</style>