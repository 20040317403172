<template>
  <div class="main">
    <TopPanel />
    <div class="main__content">
      <ApiModeWrapper :title="apimodes[apiindex].title" :component="apimodes[apiindex].component"/>
      <TasksStatus />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopPanel from '../components/TopPanel.vue'
import ApiModeWrapper from '../components/ApiModeWrapper.vue';
import TasksStatus from '../components/TasksStatus.vue';

export default {
  name: 'Main',
  data: ()=>({
    apiindex: 0
  }),
  components: { TopPanel, ApiModeWrapper, TasksStatus },
  computed: {
    ...mapState(['Role', 'apimodes']),
  },
}
</script>
<style lang="sass">
.downer
  display: flex
  flex-direction: column
  gap: 12px

.label
  display: grid  
  align-items: center
  gap: 12px
  grid-template-columns: auto 200px
  .label__text
    white-space: nowrap

.select
  font-size: 20px
  border-radius: 5px
.red
  background: rgba(255,0,0,.5)
.green
  background: rgba(0,255,0,.5)
.main
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black

  padding: 5px
  position: relative
  min-height: 100vh
  background: url('~@/assets/bg.webp')
  background-repeat: repeat
  &::before
    content: ''
    position: absolute
    left:  0
    top: 0
    width: 100%
    height: 100%
    background: rgba(255,255,255,.5)   
    backdrop-filter: blur(2px)
  *
    position: relative
  &__content
    margin-top: 20px
    display: flex
    align-items: flex-start
    justify-content: space-between
    gap: 40px
    textarea
      width: 400px
      height: 600px
      border-radius: 5px
      padding: 10px
      margin-bottom: 15px

</style>