<template>
  <div class="taskStatus">
    <h2 class="taskStatus__title">{{ tasks.length ? 'Статус задач' : 'Задачи отсутствуют' }}</h2>
    <div class="taskStatus__wrapper">
      <table class="taskStatus__table" v-if="tasks?.length">
        <thead>
          <tr>
            <th>id</th>
            <th>статус</th>
            <th>прогресс</th>
            <th>обновлено?</th>
            <th>действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in tasks" :key="task._id" class="taskline" :class="{ 'taskline_current': toDownload.includes(task.id) }">
            <td>{{ task.id }}</td>
            <td>{{ task.status }}</td>
            <td>{{ task.progress }}</td>
            <td :class="task.updateOk ? 'green' : 'red'">{{ task.updateOk ? 'Да' : 'Ошибка обновления' }}</td>
            <td class="taskline__actions">
              <Btn v-if="task.status == 'Done'" :small="true" @click="download(task.id)" @btnMounted="downloadIfNeeded(task.id)"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_iconCarrier">
                    <path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="white" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg></Btn>
              <Btn v-if="task.status != 'In progress'" :small="true" @click="deleteTask(task.id)">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z" fill="white"></path>
                  </g>
                </svg>
              </Btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.cjs.js';
import Btn from './buttons/Btn.vue';

export default {
  data: () => ({
    interval: null
  }),
  components: {
    Btn
  },
  methods: {
    downloadIfNeeded(id) {
      if (this.toDownload.find(el => ('' + id) == ('' + el))) {
        this.$store.commit('setToDownload', this.toDownload.filter(x => x != id))
        this.download(id)
      }
    },
    download(id) {
      fetch(this.url + 'taskResult?id=' + id, { credentials: 'include' })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          return res.blob()
        }).then(blob => {
          if (blob != null && blob.size > 0) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'data.xlsx';
            document.body.appendChild(a);
            a.click();
          } else {
            throw ('Ошибка!\nФайл пуст')
          }
        }).catch(err => { console.error(err); alert(err) })
    },
    deleteTask(id) {
      fetch(this.url + 'task', {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          this.updateTasks()
        }).catch(err => { console.error(err); alert(err) })
    },
    updateTasks() {
      fetch(this.url + 'allTasks', { credentials: 'include' })
        .then(res => {
          if (!res.ok) throw (res.text())
          return res.json()
        }).then(json => {
          json.reverse()
          this.$store.commit('setTasks', json)
        }).catch(err => { console.error(err); alert(err) })
    }
  },
  mounted() {
    if (!this.interval) {
      this.updateTasks()
      this.interval = setInterval(this.updateTasks, 4000)
    }
  },
  unmounted() {
    clearInterval(this.interval)
    this.interval = null
  },
  computed: {
    ...mapState(['tasks', 'url', 'toDownload'])
  }
}
</script>

<style lang="sass">
.taskStatus
  &__title
    font-size: 20px
    color: #222
  &__wrapper
    max-height: 800px
    overflow: auto
  &__table
    border-collapse: collapse
    th
      font-weight: 500
    th, td
      text-align: center
      min-width: 50px
      padding: 5px 15px
      border: 1px solid rgba(0, 0, 0, 0.2)

.taskline
  &_current
    background: rgba(0, 0, 255, .2)
  &__actions
    .btn
      padding: 5px
      display: inline-flex
      justify-content: center
      align-items: center
      &:not(:last-child)
        margin-right: 10px
    svg
      width: 20px !important
  
</style>